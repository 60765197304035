import React, { useState } from "react";

//cmps
import Thanks from '../thank/index'

// import { useHistory } from 'react-router-dom'
import { Formik } from 'formik';
import { Link } from "react-router-dom";

import axios from 'axios'

import { registerSchema } from '../../validation';
import Input from '../../components/input';
import TextArea from '../../components/textArea'
import Checkbox from '../../components/checkbox'
import { toaster } from '../../components/toaster';
import Loader from "../../components/loader";

import LoginImage from '../../images/login-logo.png'

export default function Register(props) {

    const [loading, setLoading] = useState(false)
    const [ showThank, setShowThank ] = useState(false)
    const [ uniCode, setUniCode ] = useState('')
    // ttGMB2
    const handleCloseThankUI = _ => {
        setShowThank(false)
        window.location.reload()
    }
    return (
        <Formik
            validationSchema={registerSchema}
            initialValues={{
                username: '',
                email: '',
                phoneNumber: '',
                address: '',
                terms: false
            }}
            onSubmit={(values, { resetForm }) => {
                setLoading(true)
                const { email, username, phoneNumber, address } = values
                const data = { name: username, email, phone: phoneNumber, address }
                axios.post('https://api.demotestingsite.com/addCustomerInfo', data, {
                    headers: {
                        "Content-Type": "application/json",
                    }
                })
                    .then((res) => {
                        if (res.data.code === 200) {
                            setUniCode(res.data.data.uniqueCode)
                            resetForm();
                            setLoading(false);
                            setShowThank(true)
                            toaster(res.data.message, "success");
                        } else {
                            resetForm();
                            setShowThank(false)
                            setLoading(false);
                            toaster(res.data.message, "error");
                        }
                    })
                    .catch((err) => {
                        resetForm();
                        setShowThank(false)
                        setLoading(false);
                        toaster("!Oops, Something went wrong", "error");
                    });
            }}
        >
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                isSubmitting,
                setFieldValue
            }) => {
                return (

                    <div className="container">
                        <div className="login-main">
                            <div className="card">
                                <div className="welcome">
                                    <h5>Welcome</h5>
                                    <p>Register to continue to Nodwin.</p>
                                </div>

                                <div className="card-body">
                                    <img className="login-logo" src={LoginImage} alt="login-logo" />
                                    {
                                        !showThank ? (
                                            <form noValidate="" className="form-horizontal" onSubmit={handleSubmit}>
                                        <div className="form-group">
                                            <Input
                                                id="userName"
                                                name="username"
                                                placeholder="Enter Customer name"
                                                type="text"
                                                label="Customer Name"
                                                value={values.username}
                                                onChange={handleChange}
                                                error={touched.username && errors.username}
                                                errorText={errors.username}
                                                disabled={isSubmitting}
                                            />
                                        </div>

                                        <div className="form-group">
                                            <Input
                                                id="email"
                                                name="email"
                                                placeholder="Enter Email"
                                                type="text"
                                                label="Email"
                                                value={values.email}
                                                onChange={handleChange}
                                                error={touched.email && errors.email}
                                                errorText={errors.email}
                                                disabled={isSubmitting} />
                                        </div>

                                        <div className="form-group">
                                            <Input
                                                id="phoneNumber"
                                                name="phoneNumber"
                                                placeholder="Enter Phone Number"
                                                type="text"
                                                label="Phone Number"
                                                value={values.phoneNumber}
                                                onChange={handleChange}
                                                error={touched.phoneNumber && errors.phoneNumber}
                                                errorText={errors.phoneNumber}
                                                disabled={isSubmitting} />
                                        </div>

                                        <div className="form-group">
                                            <TextArea
                                                id="address"
                                                name="address"
                                                placeholder="Enter Address"
                                                type="text"
                                                label="Address"
                                                value={values.address}
                                                onChange={handleChange}
                                                error={touched.address && errors.address}
                                                errorText={errors.address}
                                                disabled={isSubmitting}
                                            />
                                        </div>

                                        <div className="form-group" style={{ border: '' }}>
                                            <div className="form-check">
                                                <Checkbox
                                                    id="terms"
                                                    type="checkbox"
                                                    label=" Terms and Condition"
                                                    name="terms"
                                                    onChange={ handleChange }
                                                    // onChange={(e) => { setFieldValue('terms', e.currentTarget.checked) }}
                                                    error={touched.terms && errors.terms}
                                                    errorText={errors.terms}
                                                    disabled={isSubmitting}
                                                />
                                            </div>

                                        </div>
                                        <div className="form-group login-btn">
                                            <button disabled={isSubmitting} type="submit" className="login-btn1 btn mt-3 ">
                                                {loading ? <Loader /> : 'Register'}
                                            </button>
                                        </div>
                                        <div className="forgot text-center">
                                            <Link to="/forgot">
                                                <i className="bx bxs-lock"></i> Forgot your Unique Code ?
                                            </Link>
                                        </div>
                                    </form>
                                        ) : <Thanks uniCode={ uniCode } handleClose={ handleCloseThankUI }/> 
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }}
        </Formik>
    );
}
